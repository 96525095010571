<template>
  <v-card class="mx-auto elevation-0" height="100%">
    <v-toolbar class="kalan-titles elevation-0" color="primary--text">
      <v-toolbar-title>
        <span>{{ $vuetify.lang.t(`$vuetify.${model}.plural_name`) }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div style="margin-right: 20px;">
        <v-text-field flat label="Buscar" hide-details v-model="search" append-icon="mdi-magnify"></v-text-field>
      </div>
      <actions-button :item="{}" :actions="actions"></actions-button>
    </v-toolbar>
    <kalan-list :loading="loading" :getTitle="getTitle" :getSubTitle="getSubTitle" :getExtra="getExtra" :items="items"
      :search="search" :actions="listActions">
    </kalan-list>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import ApiService from '@/util/api.service'
import Users from '@/models/users.model'
import Crud from '@/util/crud.service'
import UsersService from '@/util/users.service'

export default {
  props: {
    model: {
      default: 'users'
    },
  },
  components: {
    'kalan-list': () => import('@/components/modulo/List.vue'),
    'actions-button': () => import('@/components/modulo/Actions.vue')
  },
  computed: {
    ...mapGetters('auth', ['getUser'])
  },
  data() {
    return {
      items: [],
      loading: true,
      search: '',
      attributeFields: [
        'alarm_sensor',
        'ptz_control',
        'internet',
        'whatsappId',
        'keywordsWhatsapp',
        'btnAlertsWhatsapp',
        'cameraAlertsWhatsapp',
        'incidentsAlertsWhatsapp',
        'permission_murphy',
        'consulta_murphy',
        'permission_recordings'
      ],
      actions: [{
        help: window.VMA.$vuetify.lang.t(`$vuetify.${this.model}.create`),
        icon: 'mdi-plus',
        color: 'secondary',
        action: (item) => {
          const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
          let result = '';
          for (let i = 0; i < 32; i++) {
            result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
          }
          item.token = result
          Crud.create({
            item, schema: Users, model: this.model, preSave: this.preSave
          }).then(this.load)
        }
      }],
      listActions: [{
        help: 'Cambiar contraseña',
        icon: 'mdi-account-key',
        color: 'green',
        action: (item) => {
          UsersService.changePassword({
            user: item,
            omitFields: this.attributeFields
          }, this)
        }
      }, {
        help: window.VMA.$vuetify.lang.t(`$vuetify.${this.model}.permissions`),
        icon: 'mdi-key',
        color: 'yellow',
        action: (item) => {
          UsersService.groups({
            user: item
          })
        }
      }, {
        help: 'Geocercas',
        icon: '$geofence-white',
        color: 'orange',
        action: (item) => {
          UsersService.geofences({
            user: item
          })
        }
      }, {
        help: window.VMA.$vuetify.lang.t(`$vuetify.${this.model}.update`),
        icon: 'mdi-pencil',
        color: 'accent',
        action: (item) => {
          Crud.update({
            item,
            schema: Users,
            model: this.model,
            title: this.getTitle(item),
            preSave: this.preSave
          }).then(this.load)
        }
      }, {
        help: window.VMA.$vuetify.lang.t(`$vuetify.${this.model}.read`),
        icon: 'mdi-eye',
        color: 'mattBlack',
        action: (item) => {
          Crud.read({
            item,
            schema: Users,
            model: this.model,
            title: this.getTitle(item)
          })
        }
      }, {
        help: window.VMA.$vuetify.lang.t(`$vuetify.${this.model}.delete`),
        icon: 'mdi-delete',
        color: 'red',
        action: (item) => {
          Crud.delete({
            id: item.id,
            model: this.model,
            title: this.getTitle(item)
          }).then(this.load)
        }
      }],
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    getTitle: (item) => item.name,
    getSubTitle: (item) => {
      return `${item.email} - ${item.workUnit || 'Desconocido'}`
    },
    getExtra: () => null,
    preSave(item) {
      const it = Object.assign(item, {})
      this.attributeFields.forEach((field) => {
        it.attributes[field] = it[field]
        delete it[field]
      })
      return it
    },
    load() {
      this.loading = true
      let url = `/${this.model}`
      if (this.getUser && this.getUser.administrator) {
        url = `/${this.model}?all=true`
      }
      ApiService({
        url,
        method: 'get',
      }).then((resp) => {
        this.items = resp.map((it) => {
          this.attributeFields.forEach((field) => {
            if (field === 'permission_murphy') {
              it.attributes[field] = it.attributes[field] === undefined ? true : it.attributes[field]
            }
            it[field] = it.attributes[field]
            delete it.attributes[field]
          })
          return it
        })
        this.loading = false
      }).catch((err) => {
        this.loading = false
      })
      ApiService({
        url: 'users/workunits',
        method: 'get'
      }).then((res) => {
        Users.workUnit.options = res.filter((a) => a)
      })
    },
  }
}
</script>
